import {
    defineComponent,
    ref,
    watch,
    nextTick,
    reactive,
} from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Flash from '@/components/Flash';
import Card from '@/components/Card';

import { parseTime } from '@/public/methods';

export default defineComponent({
    components: { Card, Flash },
    setup(props, { root }: any) {
        const { $message, $modal, $route } = root;
        const flashUrl: Ref<string | ArrayBuffer | null> = ref('');
        const flashWH = reactive({
            w: '993px',
            h: '722px',
        });
        const fileInfo = reactive({
            name: '',
            size: 0,
            type: 'application/x-shockwave-flash',
            lastModified: 0,
        });
        if (document.documentElement.offsetWidth < 600) {
            flashWH.w = '350px';
            flashWH.h = '255px';
        } else if (document.documentElement.offsetWidth < 1366) {
            flashWH.w = '550px';
            flashWH.h = '400px';
        }
        const selectedFile = (event: any) => {
            //alert('选择了文件');
            if (typeof event?.target?.files !== 'object') {
                $message.error('无法读取文件，您使用了不兼容的浏览器');
                return;
            }
            if (event.target.files.length < 1) {
                $message.error('未选择任何文件，请重新选择');
                return;
            }
            const file = event.target.files[0];
            if (typeof file !== 'object') {
                $modal('无法获取文件信息');
                return;
            }
            fileInfo.name = file.name;
            fileInfo.type = file.type;
            fileInfo.size = file.size;
            fileInfo.lastModified = file.lastModified;
            if (
                fileInfo.type !== 'application/x-shockwave-flash' ||
                fileInfo.name.substring(fileInfo.name.length - 4) !== '.swf'
            ) {
                $message.warning('请选择.swf格式的文件');
            }
            console.log(file);
            const fileReader = new FileReader();
            fileReader.onload = function () {
                //console.log(this.result);
                flashUrl.value = '';
                nextTick(() => {
                    flashUrl.value = this.result;
                });
            };
            fileReader.readAsDataURL(file);
        };
        const showSize = (num: number) => {
            if (num / 1024 / 1024 > 1) {
                return (num / 1024 / 1024).toFixed(2) + 'MB';
            } else if (num / 1024 > 1) {
                return (num / 1024).toFixed(2) + 'KB';
            } else {
                return num + 'B';
            }
        };
        watch(
            () => fileInfo.name,
            value => {
                document.title =
                    '' + value + ' - ' + $route?.meta?.metaInfo?.title;
            }
        );
        return {
            selectedFile,
            flashUrl,
            flashWH,
            fileInfo,
            showSize,
            parseTime,
        };
    },
});
