





















































import { defineComponent, onMounted, ref } from '@vue/composition-api';

import Card from '@/components/Card';
import { Btn } from '@/components/UI';
import axios from 'axios';

export default defineComponent({
    components: { Card, Btn },
    setup(props, { root }: any) {
        const { $message } = root;
        const videoElement = ref(null);
        const videoSrc = ref('/static/js/package/flv-js/easy-love.flv');
        const isOpenPlayer = ref(false);
        let isReady = false;
        let oFlvjs: any = null;
        const loadVideo = () => {
            if (!videoSrc.value) {
                $message.error('请输入URL');
            }
            if (!isReady || !oFlvjs) {
                $message.error('播放器还没准备好，别急');
            }
            /*
            axios
                .get(videoSrc.value)
                .then(response => {
                    //if()
                    if (
                        typeof response?.headers['content-type'] === 'string' &&
                        response.headers['content-type'].includes('video/x-flv')
                    ) {
                        //
                    } else {
                        $message.error('URL不是有效的.flv格式');
                    }
                })
                .catch(error => {
                    console.log('URL输入错误', error.code);
                });
                */
            isOpenPlayer.value = true;
            const flvPlayer = oFlvjs.createPlayer({
                type: 'flv',
                isLive: true,
                //cors: true,
                hasAudio: true,
                url: videoSrc.value,
            });
            if (!videoElement.value) {
                $message.error('DOM元素注册失败!');
                return;
            }
            flvPlayer.attachMediaElement(videoElement.value);
            //
            try {
                flvPlayer
                    .load()
                    ?.then()
                    ?.catch(() => {
                        $message.error('加载失败');
                    });
            } catch (e) {
                $message.error('无法加载flv资源:' + e);
            }
            try {
                flvPlayer
                    .play()
                    ?.then()
                    ?.catch(() => {
                        $message.error('无法正常播放');
                        flvPlayer.destory();
                    });
            } catch (e) {
                $message.error('播放失败' + e);
            }
        };
        new Promise((resolve, reject) => {
            if (typeof (window as any).flvjs !== 'object') {
                const oScript = document.createElement('script');
                oScript.setAttribute('src', '/static/js/package/flv-js/flv.js');
                oScript.addEventListener('load', () => {
                    resolve((window as any).flvjs);
                });
                oScript.addEventListener('error', () => {
                    reject('无法导入程序包');
                });
                document.head.appendChild(oScript);
            } else {
                const { flvjs } = window as any;
                if (flvjs.FlvPlayer) {
                    resolve(flvjs);
                } else {
                    reject('程序包出错');
                }
            }
        })
            .then((flvjs: any) => {
                if (
                    !flvjs ||
                    typeof flvjs !== 'object' ||
                    !flvjs.isSupported()
                ) {
                    throw '浏览器不支持或无法解析';
                }
                $message.info('播放器准备就绪');
                isReady = true;
                oFlvjs = flvjs;
            })
            .catch(error => {
                $message.error('' + error);
            });
        return { videoElement, videoSrc, loadVideo, isOpenPlayer };
    },
});
