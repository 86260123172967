






























































import {
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
} from '@vue/composition-api';

import Card from '@/components/Card';

import { Btn, Inp } from '@/components/UI';
import axios from 'axios';

export default defineComponent({
    components: { Card, Btn, Inp },
    setup(props, { root }: any) {
        const { $message } = root;
        let swfData: any = {};
        let canImportPackage = true;
        const packageArr = ref([
            'fanvas3-all',
            'fanvas3-min',
            //'fanvas3-no-filter',
            //'fanvas3-no-filter-min',
            //'runtime_src/fanvas',
        ]);
        const inputValue = ref('/static/js/package/fanvas/demo/swfData.js');
        const importPackage = (pkName: string): void => {
            if (!canImportPackage) {
                $message.error('当前状态不允许导入');
                return;
            }
            (window as any).fanvas = undefined;
            try {
                document.head
                    .querySelectorAll('[data-fanvas-package]')
                    .forEach(item => {
                        try {
                            (item.parentNode as any).removeChild(item);
                        } catch (e) {
                            //
                        }
                    });
            } catch (e) {
                //
            }
            if (!packageArr.value.includes(pkName)) {
                $message.error('没有导入权限');
                return;
            }
            const oScript = document.createElement('script');
            oScript.setAttribute(
                'src',
                `/static/js/package/fanvas/${pkName}.js`
            );
            oScript.addEventListener('error', () => {
                $message.error('无法加载程序包');
            });
            oScript.setAttribute('data-fanvas-package', 'true');
            oScript.addEventListener('load', () => {
                if (typeof (window as any).fanvas !== 'undefined') {
                    $message.success('已成功导入');
                } else {
                    $message.warning(
                        '程序包已导入，但无法访问全局变量，您可尝试重试，或与技术人员联系'
                    );
                }
            });
            document.head.appendChild(oScript);
        };
        const doRun = () => {
            if (typeof (window as any).fanvas !== 'object') {
                $message.error('无法运行，您可能需要先导入程序包');
                return;
            }
            new Promise((resolve, reject) => {
                axios
                    .get(inputValue.value)
                    .then(response => {
                        const { data } = response;
                        //console.log(data);
                        const yz = 'var swfData = ';
                        if (!data.includes(yz) || data.indexOf(yz) < 0) {
                            reject('无法读取swfData文件');
                            return;
                        }
                        const newData = data.substring(
                            data.indexOf(yz) + yz.length,
                            data.length - 1
                        );
                        swfData = JSON.parse(newData) || {};
                        resolve(swfData);
                        /*
                        const oScript = document.createElement('script');
                        oScript.innerHTML = data;
                        oScript.addEventListener('load', () => {
                            resolve(swfData);
                        });
                        document.head.appendChild(oScript);
                        resolve(swfData);
                        */
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
                .then(swfData => {
                    canImportPackage = false;
                    $message.info('开始运行Fanvas程序');
                    //console.log(document.getElementById('fanvasTest'));
                    //
                    //console.log((window as any).fanvas.play, swfData);
                    (window as any).fanvas.play(
                        document.querySelector('canvas#fanvasTest') ||
                            'fanvasTest',
                        swfData || (window as any).swfData || {},
                        {
                            cache: false,
                            autoPlay: true,
                            onFrame: function (index: any) {
                                //
                            },
                            imagePath: inputValue.value.includes('/')
                                ? inputValue.value.substring(
                                      0,
                                      inputValue.value.lastIndexOf('/') + 1
                                  )
                                : './',
                        }
                    );
                    //
                })
                .catch(error => {
                    $message.error('' + error);
                });
        };
        const changeDomSize = () => {
            const dom = document.querySelector('canvas#fanvasTest');
            if (typeof dom !== 'object' || !dom || dom === null) {
                return;
            }
            const clientWidth = document.documentElement.clientWidth;
            if (clientWidth < 550) {
                dom.setAttribute('width', '' + Math.floor(clientWidth - 25));
                //dom.setAttribute(
                //    'height',
                //    '' + Math.floor((clientWidth - 25) / 1.375)
                //);
            } else if (clientWidth < 1366) {
                dom.setAttribute('width', '' + 550);
                //dom.setAttribute('height', '' + 550 / 1.375);
            } else {
                dom.setAttribute('width', '' + 992);
                //dom.setAttribute('height', '' + 567);
            }
        };
        onMounted(() => {
            changeDomSize();
            window.addEventListener('resize', changeDomSize);
        });
        onBeforeUnmount(() => {
            window.removeEventListener('resize', changeDomSize);
        });
        return { packageArr, importPackage, inputValue, doRun };
    },
});
